@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,700&family=Noto+Sans+Hebrew:wght@100;200;300;400;500;600;700&display=swap');

// font family
$font-family-default: 'Lato', sans-serif;

// font weights
$font-w-light: 300;
$font-w-regular: 400;
$font-w-bold: 700;

// font size
$font-s-small: 12px;
$font-s-medium: 14px;
$font-s-big: 20px;

// inline hights
$base-line-height: normal;

// border radius
$border-radius-1: 2px;
$border-radius-2: 4px;
$border-radius-3: 6px;

// [breakpoint - variables]
// breakpoints
$breakpoints: (
  'small': (
    min-width: 576px,
  ),
  'medium': (
    min-width: 768px,
  ),
  'large': (
    min-width: 992px,
  ),
  'extra': (
    min-width: 1200px,
  ),
);

// [margins and paddings - variables]
// paddings
$navigation-padding: 1.25rem;
$card-padding: 1.25rem;
$button-icon-padding: 0.25rem;

// [shadow - variables]
$box-shadow-light: 0px 12px 48px 0px var(--black-color-15);
