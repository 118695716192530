.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 24px;
  border-bottom: 1px solid var(--neutral-medium);
  height: 3rem;
  padding: 0 1.5rem;
  color: var(--text-color-toolbar);

  i {
    cursor: pointer;
  }
}

.dropdown-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 24px;
  padding: 1.25rem 1.5rem;
  gap: 1.25rem;

  h4 {
    font-weight: $font-w-bold;
    color: var(--title-color);
  }

  .dropdown-body {
    display: flex;
    align-items: center;
  }

  .products-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
}
