.dropdown-content {
  position: absolute;
  background-color: var(--background-color-dropdown);
  color: var(--text-color-dropdown);
  width: 16rem;
  height: fit-content;
  right: 0;
  top: 3.5rem;
  box-shadow: $box-shadow-light;
}
