%ease {
  transition: all 0.35s ease-out;
  -webkit-transition: all 0.35s ease-out !important;
}

%toolbar-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color-toolbar);
  color: var(--text-color-toolbar);
  min-height: 3rem;
  width: 100%;
  border-bottom: 1px solid var(--neutral-medium);
  padding: 0 1.75rem;
}
