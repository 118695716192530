body {
  line-height: $base-line-height;
  color: var(--neutral-dark);
  font-family: $font-family-default;
}

body,
input,
select,
textarea {
  font-family: $font-family-default;
}

h3 {
  font-size: $font-s-big;
  font-family: $font-family-default;
  font-weight: $font-w-regular;
  margin: 0;
}

h4 {
  font-size: $font-s-medium;
  font-family: $font-family-default;
  font-weight: $font-w-regular;
  margin: 0;
}

p {
  font-size: $font-s-medium;
}
