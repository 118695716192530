.text-input-wrapper {
  display: flex;
  background-color: var(--neutral-light);
  padding: 0.5rem;
  border-radius: $border-radius-1;
  align-items: center;
  height: 2rem;
  width: 14rem;
}

.text-input-prefix,
.text-input-suffix {
  color: var(--neutral-dark);
  margin-right: 0.5rem;
}

.text-input-suffix {
  cursor: pointer;
}

.text-input,
.text-input:focus {
  border: none;
  outline: none;
  background-color: transparent;
  flex: 1;
  padding: 0;
}

@include respond-to('medium') {
  .text-input-wrapper {
    width: 21.5rem;
  }
}
