.toolbar {
  @extend %toolbar-wrapper;

  .search-wrapper {
    display: flex;
  }

  .tools-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    .view-mode-toggles {
      display: flex;
      gap: 0.2rem;
    }
  }

  .toolbar-name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .navigation-icon {
    &.hover {
      cursor: pointer;
    }
  }
}
