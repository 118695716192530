.products-grid-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--text-primary-color-medium);
  line-height: 24px;
  font-size: $font-s-medium;
  padding: 1.25rem;

  @include respond-to('medium') {
    padding: 2.5rem 2.5rem 2.5rem 5rem;
  }

  .cards-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
}
