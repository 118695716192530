.btn-icon {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.188rem;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: $border-radius-2;
  margin-left: 0.4rem;

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  &:hover {
    background-color: var(--neutral-light);
  }
}

.btn-neutral {
  background: none;
  border: none;
  outline: none;
  color: inherit;
  cursor: pointer;
}

.active {
  background-color: var(--neutral-light);
}
