.detail-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-main);
  color: var(--text-color-product-details);

  .btn-neutral {
    text-align: right;
    padding: 0.8rem 2rem;
  }
}
