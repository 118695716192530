/* Default Colors */
:root {
  /* Branding */
  --primary-color: #006fff;

  /* Neutrals */
  --neutral-light: #f6f6f8;
  --neutral-medium: #ededf0;
  --neutral-medium-dark: #dbdce1;
  --neutral-dark: #838691;

  /* Transparent */
  --transparent-color: transparent;

  /* Whites */
  --white-color: #ffffff;

  /* Blacks */
  --black-color-15: rgba(0, 0, 0, 0.15);
  --black-color-45: rgba(0, 0, 0, 0.45);
  --black-color-85: rgba(26, 29, 44, 0.85);

  /* Dark mode colors */
  --dark-mode-light: #182543;
  --dark-mode-medium: #0f0f3e;
  --dark-mode-dark: #05192e;

  /* Text Colors */
  --text-primary-color: var(--black-color-85);
  --text-primary-color-medium: var(--neutral-dark);
  --text-primary-color-light: var(--black-color-45);
  --text-white-color: var(--white-color);
}

.App.light {
  --title-color: var(--black-color-85);
  --background-color-main: var(--white-color);

  --background-color-nav: var(--white-color);
  --text-color-nav: var(--text-primary-color);

  --background-color-toolbar: var(--white-color);
  --text-color-toolbar: var(--text-primary-color-light);

  --background-color-dropdown: var(--white-color);
  --text-color-dropdown: var(--text-primary-color-light);

  --text-color-product-list: var(--text-primary-color-medium);
  --span-color-product-list: var(--text-primary-color-light);
  --hover-color-product-list: var(--neutral-light);

  --text-color-product-details: var(--text-primary-color-medium);
}

.App.dark {
  --title-color: var(--text-white-color);
  --background-color-main: var(--dark-mode-light);

  --background-color-nav: var(--dark-mode-light);
  --text-color-nav: var(--text-white-color);

  --background-color-toolbar: var(--dark-mode-light);
  --text-color-toolbar: var(--text-white-color);

  --background-color-dropdown: var(--dark-mode-medium);
  --text-color-dropdown: var(--text-primary-color-light);

  --text-color-product-list: var(--text-white-color);
  --span-color-product-list: var(--text-white-color);
  --hover-color-product-list: var(--dark-mode-dark);

  --text-color-product-details: var(--text-white-color);
}
