.card {
  height: 12rem;
  width: 15rem;
  border-radius: $border-radius-3;
  background-color: var(--white-color);
  border: 1px solid var(--neutral-medium-dark);
  flex: 0 1 calc(20% - 1.5rem);
  overflow: hidden;
  transition:
    box-shadow 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  flex: 0 1 calc(100%);

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
    cursor: pointer;
  }

  .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--neutral-medium);
    height: 7.813rem;

    img {
      height: 100%;
      width: auto;
    }
  }

  .description-wrapper {
    padding: 0.75rem;

    h4 {
      color: var(--text-primary-color);
    }

    span {
      font-size: $font-s-small;
    }
  }

  @include respond-to('small') {
    flex: 0 1 calc(100%);
  }
  @include respond-to('medium') {
    flex: 0 1 calc(51% - 1.5rem);
  }
  @include respond-to('large') {
    flex: 0 1 calc(25% - 1.5rem);
  }
}
