.products-list-wrapper {
  width: 100%;
  color: var(--text-color-product-list);
  line-height: 24px;
  font-size: $font-s-medium;
  padding: 1.25rem;

  @include respond-to('medium') {
    padding: 2.5rem 2.5rem 2.5rem 5rem;
  }

  @include respond-to('large') {
    th:first-child {
      width: 18%;
    }

    th:nth-child(2) {
      width: 25%;
    }

    td:first-child,
    th:first-child {
      max-width: 3.125rem;
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    padding: 0.25rem;
    border-bottom: 1px solid var(--neutral-medium);
  }

  tbody tr {
    &:hover {
      cursor: pointer;
      background-color: var(--hover-color-product-list);
    }
  }

  th {
    text-align: left;
    color: var(--title-color);
  }

  td:first-child,
  th:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td:nth-child(2),
  th:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    padding-left: 2rem;
    color: var(--span-color-product-list);
    font-weight: $font-w-light;
    font-size: $font-s-small;
  }

  img {
    width: 1.625rem;
    margin-left: 5.5rem;
  }
}
