.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color-nav);
  height: 3.5rem;
  width: 100%;
  color: var(--text-color-nav);
  border-bottom: 1px solid var(--neutral-medium);

  .logo-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  .author {
    padding: 0 1.75rem;
  }
}
