.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 2.3rem;
  margin-top: 1rem;

  .page-button {
    width: 1.4rem;
    height: 1.4rem;

    border: none;

    border-radius: $border-radius-2;

    &.active {
      color: var(--white-color);
      background-color: var(--primary-color);
      font-weight: $font-w-bold;
    }
  }

  .pagination-button {
    transition: background-color 0.3s ease;
    height: 1.6rem;
    border: none;

    &.hide-prev {
      visibility: hidden;
    }
  }

  .page-button,
  .pagination-button {
    margin: 0 0.313rem;
    background-color: var(--transparent-color);
    color: var(--text-color-product-list);
    font-size: $font-s-small;

    &:hover {
      cursor: pointer;
    }
  }
}
