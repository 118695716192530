.device-preview,
.device-body-wrapper {
  height: 100%;
}

.device-body-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;

  .device-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 1.7rem;
    margin-top: 10%;
    font-size: $font-s-small;
    margin: 2rem;
    padding-bottom: 3rem;
  }

  img {
    width: 16.063rem;
    height: auto;
    max-width: 100%;
  }

  .table {
    width: 100%;
    margin-top: 20px;
  }

  th {
    text-align: start;
    font-weight: $font-w-regular;
    width: 35% !important;
  }

  td {
    text-align: end;
    font-weight: $font-w-regular;
  }

  @include respond-to('medium') {
    .device-wrapper {
      flex-direction: row;
      justify-content: center;
      margin-top: 0.75rem;
      align-items: flex-start;

      .table {
        width: calc(2 * 6.063rem);
      }

      img {
        width: 16.063rem;
      }
    }
  }
}
