.checkbox {
  input[type='checkbox'] {
    display: none !important;
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: 2rem;
    cursor: pointer;
    color: var(--text-color-toolbar);
    line-height: 1.75rem;
    margin: 0;
    font-size: $font-s-medium;
    @extend %ease;

    &::before {
      position: absolute;
      top: 0.281rem;
      left: 0.25rem;
      width: 1rem;
      height: 1rem;
      content: '';
      border-radius: 3px;
      border: 2px solid var(--primary-color);
      background-color: var(--white-color);
      background-image: url('../../assets/icons/Checked.svg');
      background-repeat: no-repeat;
      background-size: 0.7rem;
      background-position: center;
    }

    svg {
      display: none;
    }
  }

  input:checked ~ label::before {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: transparent;
  }

  label::before {
    content: '';
    border-color: var(--neutral-dark);
  }
}
